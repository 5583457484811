import { Autocomplete, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useState } from 'react'
import { FilterDescriptionTooltip } from './QueryParameterFilter';
import { fetchAvailableFiltersData } from '../services/customReportsQueryService';
import { format } from 'date-fns';

const CustomReportParameter = ({ parameter, idToken, report, customParameterValues, setCustomParameterValues, setOrderOfLastChangedParameter, applicableOptions, isLoading, 
    triggerClearFilters, setTriggerClearFilters, clearParameterFromOrderNo, setClearParameterFromOrderNo, availableDates, setAllFiltersData, setIsLoading }) => {

    const dateFormat = 'sv';
    const [dateValue, setDateValue] = useState(null);
    const [value, setValue] = useState(null);
    const options = (parameter.staticOptions &&
                     parameter.staticOptions.length > 0 ) 
                     ? parameter.staticOptions.split('|').map(item => item.trim()) 
                     : applicableOptions;

    const disableDates = (date) => {
        return !availableDates.includes(date.toLocaleDateString(dateFormat))
    }

    useEffect(() => {
        if (parameter.name === 'ASOF_DATE' && dateValue !== null) {
            const filterParameters = {"AsOf_Date":format(dateValue, 'yyyyMMdd')};
            setAllFiltersData([]);
            fetchAvailableFiltersData(idToken, report.id, setAllFiltersData, setIsLoading, filterParameters);
        }
    }, [dateValue])

    useEffect(() => {
        if(report.reportName === 'IndexedValuesReport') {
            if ((parameter.name === 'EntityType' || parameter.name === 'EntityCode')) {
                let asOfDateFrom = customParameterValues['ASOF_DATE_FROM'] ? customParameterValues['ASOF_DATE_FROM'][0] : '';
                let asOfDateTo = customParameterValues['ASOF_DATE_TO'] ? customParameterValues['ASOF_DATE_TO'][0] : '';
                let entityType = customParameterValues['EntityType'] ? customParameterValues['EntityType'][0] : '';
                let entityCode = customParameterValues['EntityCode'] ? customParameterValues['EntityCode'][0] : '';

                if(asOfDateFrom !== '' && asOfDateTo !== '' && entityType) {
                    let filterParameters = {"AsOfDateFrom":asOfDateFrom, "AsOfDateTo":asOfDateTo, "EntityType":entityType};
                    if(entityCode) filterParameters = {...filterParameters, "EntityCode":entityCode};
                    
                    setAllFiltersData([]);
                    fetchAvailableFiltersData(idToken, report.id, setAllFiltersData, setIsLoading, filterParameters);
                }
            }
        }
    }, [value])

    useEffect(() => {
        clearParameterValues(clearParameterFromOrderNo);
    }, [triggerClearFilters]);

    function convertDateFormat(date) {
        if (date === null) return "null"
        else return date.toLocaleDateString(dateFormat)
    }

    function clearParameterValues(clearParameterFromOrderNo = 1 ) {
        if (parameter.order >= clearParameterFromOrderNo) {
            parameter.parameterType === "DateTime" ? setDateValue(null) : setValue(null);

            setCustomParameterValues(currentObj => {
                let newObj = { ...currentObj, [parameter.name]: [] }
                return newObj
            })
        }
    }

    function renderParameterInput(parameter, value, setValue, dateValue, setDateValue, disableDates,
        setCustomParameterValues, isLoading, options) {

        switch (parameter.parameterType) {
            case "DateTime":
                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={parameter.displayName}
                            inputFormat="dd/MM/yyyy"
                            value={dateValue}
                            required={parameter.required}
                            onChange={(newValue) => {
                                setAllFiltersData([]);
                                setDateValue(newValue);
                                setCustomParameterValues(currentObj => {
                                    let newObj = { ...currentObj, [parameter.name]: [convertDateFormat(newValue)] }
                                    return newObj
                                });
                                setOrderOfLastChangedParameter(parameter.order);
                                
                                setClearParameterFromOrderNo(parameter.order + 1);
                                setTriggerClearFilters(!triggerClearFilters);
                            }}
                            shouldDisableDate={disableDates}
                            renderInput={(params) => <TextField {...params} error={false} sx={{ width: '95%' }}/>}
                        />
                    </LocalizationProvider>
                )
            case "StringArray":
                return (<div style={{ display: "flex" }}>
                    <FilterDescriptionTooltip
                        title={
                            <React.Fragment>
                                <Typography>{parameter.displayName}</Typography>
                                {parameter.description}
                            </React.Fragment>
                    }>

                        <Autocomplete
                            options = {options}
                            sx={{ width: '95%' }}
                            value={value}
                            getOptionLabel={(option) => option ? option.toString() : 'No Options'}
                            renderInput = {(params) => <TextField {...params} label={parameter.displayName} variant="outlined" />}
                            autoHighlight
                            onChange = {(event, newValue) => {
                                if(newValue === null) {
                                    setClearParameterFromOrderNo(parameter.order);
                                    setTriggerClearFilters(!triggerClearFilters);
                                }
                                else{
                                    setClearParameterFromOrderNo(parameter.order + 1);
                                    setTriggerClearFilters(!triggerClearFilters);
                                }

                                setValue(newValue);
                                setCustomParameterValues(currentObj => {
                                    var formattedValue = newValue.includes(',') ? '"' + newValue + '"' : newValue;
                                    let newObj = { ...currentObj, [parameter.name]: [formattedValue] }
                                    return newObj
                                })
                                setOrderOfLastChangedParameter(parameter.order);
                            }}
                            loading = {isLoading}
                        />
                    </FilterDescriptionTooltip>
                </div>
                )
            default:
                return
        }
    }

    return (
        <div style={{ marginBottom: "10px" }}>
            {renderParameterInput(parameter, value, setValue, dateValue, setDateValue, disableDates,
                setCustomParameterValues, isLoading, options)}
        </div>
    )
}

export default CustomReportParameter