import { Box, Button, ButtonGroup, CircularProgress, Divider, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { formatCustomParametersIntoUrl, downloadFile, fetchAvailableDates } from '../services/customQueryService';
import { formatCustomParametersIntoFormData } from '../services/customReportsQueryService';
import CustomReportParameter from './CustomReportParameter';
import { getSettings } from '../../lib/services/settings';
import doRequest from '../services/apiRequestor';

const CustomReportItem = ({ user, item, idToken, setErrorMessage, setMessage, setClassName, setShowBanner }) => {
    const enabled = item.enabled
    const [downloadUrl, setDownloadUrl] = useState(`/api/DownloadCustomReport/?reportId=${item.id}`);
    const [customParameterValues, setCustomParameterValues] = useState({});
    const [customParameterUrl, setCustomParameterUrl] = useState(null);
    const [triggerClearFilters, setTriggerClearFilters] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [allFiltersData, setAllFiltersData] = useState([]);
    const [applicableOptions, setApplicableOptions] = useState({});
    const [availableDates, setAvailableDates] = useState([]);
    const [orderOfLastChangedParameter, setOrderOfLastChangedParameter] = useState({});
    const [clearParameterFromOrderNo, setClearParameterFromOrderNo] = useState(1);
    const containerName = 'custom-reports';

    
    const poll = async function (fn, data, fnCondition, ms) {
        let result = await fn(data);
        console.log(result)
        while (fnCondition(result)) {
            console.log("Report still running, retrying later...")
            await wait(ms);
            result = await fn(data);
        }
        console.log("Report finished running.")
        return result;
    };

    const wait = function (ms = 1000) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    };

    let fetchData = async (data) => {
        let fetchResult;
        await doRequest(`/api/IsDownloadComplete`, idToken, {
            method: "POST",
            body: JSON.stringify(data)
        })
            .then((response) => {
                console.log(response.status);
                fetchResult = response;
            })
            .catch((e) => {
                let error = JSON.parse(e.message);
                setErrorMessage(error.body);
                setLoading(false);
                setShowBanner(false);
            })
        return fetchResult;
    };
    async function downloadData() {
        console.log("Downloading custom report...");
        setMessage('Downloading report...Please do not refresh or close the window.')
        setClassName("banner banner-warning");
        setShowBanner(true);
        setErrorMessage(null);
        let validate = response => response.status === 202;

        const path = downloadUrl;
        const settings = await getSettings();
        const pathConstruct = !path.includes('http') ? `${settings.baseUrl}${path}` : path;
        let downloadResponse = null;
        await doRequest(pathConstruct, idToken, {
            method: "GET"
        })
            .then(async (response) => {
                if (!response.isError) {
                    downloadResponse = response;
                }
                else {
                    setLoading(false);
                    setShowBanner(false);
                    setErrorMessage(response.data);
                }
            })
            .catch((e) => {
                let error = JSON.parse(e.message);
                setErrorMessage(error.body);
                setLoading(false);
                setShowBanner(false);
            });
        const pollingInterval = 60000;// 1 mins polling interval                   
        let isDownloadReadyResponse = await poll(fetchData, downloadResponse.data, validate, pollingInterval);
        console.log(isDownloadReadyResponse);
        if (isDownloadReadyResponse.ok) {
            const jsonData = isDownloadReadyResponse.data;
            const downloadUri = `/api/DownloadReport/?containerName=${containerName}&&fileName=${jsonData.output}`;
            const downloadPath = !downloadUri.includes('http') ? `${settings.baseUrl}${downloadUri}` : downloadUri;
            if (jsonData.output) {
                processDownloading(downloadPath, jsonData.output);
                setTimeout(() => {
                    setLoading(false);
                    setShowBanner(false);
                }, 5000);
            }
            else {
                setErrorMessage("Missing file to download.");
                setLoading(false);
                setShowBanner(false);
            }
        }
        else {
            const error = isDownloadReadyResponse.data;
            setErrorMessage(error);
            setLoading(false);
            setShowBanner(false);
        }
    }

    let processDownloading = async (path, fileName) => {
        const response = await doRequest(path, idToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        });
        if (!response.isError) {
            var file = { content: response.data, fileName: fileName };
            downloadFile(file);
        } else {
            setErrorMessage('Failed to download file:', response.statusText);
            setLoading(false);
            setShowBanner(false);
        }
    }

    useEffect(() => {
        setCustomParameterValues({});
        var dateFilterExists = item.customParameters.filter(p => p.parameterType === "DateTime");
        if (dateFilterExists && dateFilterExists.length > 0) {
            fetchAvailableDates(idToken, item.dataProductsForAvailableDates, setAvailableDates);
        }
        const addUrl = formatCustomParametersIntoUrl(customParameterValues, setCustomParameterUrl)
        setDownloadUrl(`/api/DownloadCustomReport/?reportId=${item.id}${addUrl}`)
    }, [item.name])

    useEffect(() => {
        filterDistinctParameterOptions(allFiltersData);
    }, [allFiltersData])

    useEffect(() => {
        var ignoreTillOrderNo = getMinOrderNoToIgnoreConditionalFiltering(item);
        conditionallyFilterOptions(allFiltersData, customParameterValues, orderOfLastChangedParameter, ignoreTillOrderNo);
    }, [customParameterValues, orderOfLastChangedParameter])

    useEffect(() => {
        const addUrl = formatCustomParametersIntoUrl(customParameterValues, setCustomParameterUrl)
        setDownloadUrl(`/api/DownloadCustomReport/?reportId=${item.id}${addUrl}`)
    }, [customParameterValues])

    function getMinOrderNoToIgnoreConditionalFiltering(item) {
        if(item.reportName === 'IndexedValuesReport'){ 
            var  parameter = item.customParameters.filter(p => p.name == "EntityType");
            return parameter && parameter.length > 0 ? parameter[0].order : 0;
        }
        else return 0;
    }
    const handleClearFilterClick = (event) => {
        setClearParameterFromOrderNo(1);
        setTriggerClearFilters(!triggerClearFilters);
        setAllFiltersData([]);
    }

    const filterDistinctParameterOptions = (allFiltersData) => {
        item.customParameters.map((parameter) => {
            let options = [];

            if (allFiltersData !== undefined && allFiltersData !== null && allFiltersData.length > 0) {
                options = [...new Set(allFiltersData.map(item => item[parameter.name]))];
            }

            setApplicableOptions(currentObj => {
                options.sort();
                let newObj = { ...currentObj, [parameter.name]: options }
                return newObj
            });
            return options;
        })
    }

    const conditionallyFilterOptions = (availableData, customParamValues, orderOfLastChangedParam, ignoreTillOrderNo) => {
        item.customParameters.map((parameter) => {

            let options = [];
            if(parameter.order > orderOfLastChangedParam){
                if (availableData !== undefined && availableData !== null && availableData.length > 0) {

                    let formData = formatCustomParametersIntoFormData(customParamValues, item.customParameters, orderOfLastChangedParam, ignoreTillOrderNo);
                    
                    let filterdOptions = availableData.filter(item => {
                        return Object.entries(formData).every(([key, value]) => item[key] === value);
                    });

                    if (filterdOptions !== undefined && filterdOptions !== null) {
                        options = [...new Set(filterdOptions.map(item => item[parameter.name]).filter(value => value != null))]
                    }
                }

                setApplicableOptions(currentObj => {
                    options.sort();
                    let newObj = { ...currentObj, [parameter.name]: options }
                    return newObj
                });
            }
            return options;
        })
    }

    return (
        enabled &&
        <Grid item xs={12} style={{ marginTop: "10px", marginLeft: "10px" }}>
            <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" sx={{ marginTop: "10px", fontWeight: 'bold', marginBottom: "10px" }}>
                        {item.displayName}
                    </Typography>
                    <Divider flexItem />

                </Grid>
                <Grid item xs={12} xl={5}>
                    <List dense={true}>
                        <ListItem>
                            <ListItemText primary="Description" secondary={item.description}></ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Recommended Usage" secondary={item.recommendedUsage}></ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Data Products Included" secondary={item.dataProducts} sx={{ overflowWrap: "break-word" }}></ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Requested By" secondary={item.requestedBy}></ListItemText>
                        </ListItem>
                    </List>
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', xl: 'block' } }} />
                <Grid item xs={6} xl={4}>
                    <Typography sx={{ marginTop: "15px" }} variant="subtitle2">Parameters</Typography>
                    {
                        (!item.customParameters || item.customParameters.length === 0) ? <p></p> :
                                <Grid sx={{ marginBottom: "10px", display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button onClick={handleClearFilterClick}><u>Clear Filters</u></Button>
                                </Grid>
                    }
                    {
                        (!item.customParameters || item.customParameters.length === 0)
                            ?
                            <Typography variant="subtitle2" sx={{ color: "#666666" }}>No parameters for this custom dataset.</Typography>
                            :
                                item.customParameters.map((parameter) =>
                                    <CustomReportParameter
                                        parameter={parameter}
                                        idToken={idToken}
                                        report={item}
                                        customParameterValues = {customParameterValues}
                                        setCustomParameterValues={setCustomParameterValues}
                                        setOrderOfLastChangedParameter={setOrderOfLastChangedParameter}
                                        applicableOptions={applicableOptions[parameter.name]}
                                        isLoading={isLoading}
                                        triggerClearFilters={triggerClearFilters}
                                        setTriggerClearFilters={setTriggerClearFilters}
                                        clearParameterFromOrderNo={clearParameterFromOrderNo}
                                        setClearParameterFromOrderNo={setClearParameterFromOrderNo}
                                        availableDates={availableDates}
                                        setAllFiltersData={setAllFiltersData}
                                        setIsLoading={setIsLoading}
                                    />
                            )
                    }
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', xl: 'block' } }} />
                <Grid item xs>
                    <Typography sx={{ marginTop: "15px" }} variant="subtitle2">Options</Typography>
                    <Box
                        display="flex"
                        sx={{ justifyContent: { xs: "left", xl: "center" }, alignItems: { xs: "left", xl: "center" } }}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <ButtonGroup orientation="vertical">
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setLoading(true);
                                    downloadData()
                                }}>
                                Download {loading === true && (
                                    <CircularProgress color="inherit" size={20} sx={{ marginLeft: "10px" }} />
                                )}
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ marginTop: { xs: "7px", xl: "0" } }} />
        </Grid>
    )
}

export default CustomReportItem