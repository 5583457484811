import { Autocomplete, TextField, Typography, Button, Tooltip, CircularProgress } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useState, useRef } from 'react'
import throttle from 'lodash.throttle';
import doRequest from '../services/apiRequestor';
import { formatCustomParamForFilterIntoUrl } from '../services/customQueryService';
import { FilterDescriptionTooltip } from './QueryParameterFilter';
import { Save } from '@mui/icons-material';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import SaveListDialog from './SaveListDialog';
import SearchListDialog from './SearchListDialog';
import OverwriteListDialog from './OverwriteListDialog';
import { Box } from '@mui/system';
import { regxCsvIgnoreWrappedInDoubleQuotes } from '../services/constants';

const CustomViewParameter = ({ user, idToken, parameter, view, setCustomParameterValues, customParameterValues,
    triggerClearFilters, isManageQuery, updateStausMessage, formData, productType, availableDates }) => {
    const [open, setOpen] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [value, setValue] = useState([]);
    const [dateValue, setDateValue] = useState(null);
    const [options, setOptions] = useState([]);
    //const [availableDates, setAvailableDates] = useState([]);
    const dateFormat = 'sv';
    const [openOverwriteListDialog, setOpenOverwriteListDialog] = useState(false);
    const [selectedListObj, setSelectedListObj] = useState(null);
    const [reloadSelectedList, setReloadSelectedList] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [url, setUrl] = useState(`/api/CustomViewParametersOptions/?CustomViewName=${view.name}&FieldName=${parameter.name}`);
    const originalUrl = `/api/CustomViewParametersOptions/?CustomViewName=${view.name}&FieldName=${parameter.name}`;

    const handleClickOpen = (popupName) => {
        switch (popupName) {
            case 'SaveDialog':
                if (selectedListObj !== null && selectedListObj.id && selectedListObj.id !== ""){
                    setOpenOverwriteListDialog(true);
                }
                else{
                    setOpen(true);
                }
                return
            case 'SearchListDialog':
                setOpenList(true);
                return
            default:
                return
        }
    };
    var multipleToggle = false;

    const disableDates = (date) => {
        return !availableDates.includes(date.toLocaleDateString(dateFormat))
    }

    useEffect(() => {
        if (!isManageQuery) {
            clearParameterValues();
        }
    }, [view.name]);

    useEffect(() => {
        if (customParameterValues[parameter.name] && parameter.parameterType.toLowerCase() === "stringarray") {
            setValue(customParameterValues[parameter.name])
        }
        if (customParameterValues[parameter.name] !== undefined && parameter.parameterType.toLowerCase() === "datetime") {
            setDateValue(customParameterValues[parameter.name] === [] ? null : customParameterValues[parameter.name])
        }
    }, [customParameterValues[parameter.name]])

    useEffect(() => {
        if (!isManageQuery) {
            clearParameterValues();
            setSelectedListObj(null);
        }
    }, [triggerClearFilters])


    const throttled = useRef(throttle((url, newValue) => {
        setLoading(true);
        doRequest(url + `&SearchText=${newValue}`, idToken)
            .then((response) => {
                if (!response.isError) {
                    setOptions(response.data);
                    setLoading(false);
                }
            })
            .catch((error) => { 
                setLoading(false);
                JSON.stringify(error) 
            })
    }, 2000))

    useEffect(() => {

        if(productType === "BespokeView"){
            let newUrl = formatCustomParamForFilterIntoUrl(customParameterValues, parameter.name)
            if (formData !== null) setUrl(originalUrl + newUrl)
            if (inputValue !== '') throttled.current(url, inputValue)
        }
    }, [inputValue, formData])

    function clearParameterValues() {
        parameter.parameterType === "DateTime" ? setDateValue(null) : setValue([]);
        if (parameter.parameterType === "DateTime") {
            setCustomParameterValues(currentObj => {
                let newObj = { ...currentObj, [parameter.name]: null }
                return newObj
            })
        }
        else {
            setCustomParameterValues(currentObj => {
                let newObj = { ...currentObj, [parameter.name]: [] }
                return newObj
            })
        }
    }
    if (parameter.parameterType.toString().toLowerCase() === "stringarray") multipleToggle = true;
    function convertDateFormat(date) {
        if (date === null) return "null"
        else return date.toLocaleDateString('sv')
    }
    function renderParameterInput(parameter, value, setValue, dateValue, setDateValue, disableDates, handleClickOpen,
        setCustomParameterValues, loading) {

        switch (parameter.parameterType) {
            case "DateTime":
                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={parameter.displayName}
                            inputFormat="dd/MM/yyyy"
                            value={dateValue}
                            required={parameter.required}
                            onChange={(newValue) => {
                                setDateValue(newValue);
                                setCustomParameterValues(currentObj => {
                                    let newObj = { ...currentObj, [parameter.name]: convertDateFormat(newValue) }
                                    return newObj
                                })
                            }}
                            shouldDisableDate={disableDates}
                            renderInput={(params) => <TextField {...params} error={false} sx={{ width: '95%' }} />}
                        />
                    </LocalizationProvider>
                )
            case "StringArray":
                return (<div style={{ display: "flex" }}>
                    <FilterDescriptionTooltip
                        title={
                            <React.Fragment>
                                <Typography>{parameter.displayName}</Typography>
                                {parameter.description}
                            </React.Fragment>
                        }>
                        <Autocomplete
                            sx={{ width: '70%' }}
                            multiple={multipleToggle}
                            freeSolo
                            value={value}
                            limitTags={3}
                            options={options}
                            renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    label={parameter.displayName} 
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            }
                            onChange={(event, newValue) => {

                                setCustomParameterValues(currentObj => {
                                    let newObj = { ...currentObj, [parameter.name]: [...newValue] }
                                    setValue(newValue);
                                    return newObj
                                })
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                let newFilteredOptions = newInputValue.split(regxCsvIgnoreWrappedInDoubleQuotes);
                                if ((newInputValue.split('"').length - 1) % 2 === 0 && newFilteredOptions.length > 1){
                                    let newResult = newFilteredOptions
                                                            .map(item => item.trim())
                                                            .filter(x => x);

                                    setValue(currentObj => {
                                        return [...currentObj, ...newResult]
                                    });
                                    setCustomParameterValues(currentObj => ({
                                        ...currentObj,
                                        [parameter.name] : [...currentObj[parameter.name], ...newResult]
                                    }));
                                    setInputValue("");
                                }
                                else {
                                    setInputValue(newInputValue);
                                }
                            }}
                        />
                    </FilterDescriptionTooltip>
                    {
                        !isManageQuery &&
                        <Box
                            sx={{ alignItems: 'center', display: 'flex', justifyContent: 'right' }}>
                            <Tooltip
                                title="Save List">
                                <span>
                                    <Button
                                        variant="contained"
                                        disableElevation style={{ marginLeft: "10px" }}
                                        onClick={() => handleClickOpen("SaveDialog")}>
                                        <Save />
                                    </Button>
                                </span>
                            </Tooltip>
                        </Box>
                    }
                    <Box
                        sx={{ alignItems: 'center', display: 'flex', justifyContent: 'right' }}>
                        <Tooltip
                            title="Search List">
                            <span>
                                <Button
                                    variant="contained"
                                    disableElevation style={{ marginLeft: "10px" }}
                                    onClick={() => handleClickOpen("SearchListDialog")}>
                                    <SavedSearchIcon />
                                </Button>
                            </span>
                        </Tooltip>
                    </Box>
                </div>
                )
            default:
                return
        }
    }

    return (
        <div style={{ marginBottom: "10px" }}>
            {renderParameterInput(parameter, value, setValue, dateValue, setDateValue, disableDates, handleClickOpen,
                setCustomParameterValues, loading)}
            {parameter.parameterType !== "DateTime"
                ?
                <>
                    <SearchListDialog
                        userEmail={user.mail}
                        idToken={idToken}
                        setValue={setValue}
                        open={openList}
                        setOpen={setOpenList}
                        filterName={parameter.name}
                        setCustomParameterValues={setCustomParameterValues}
                        selectedListObj={selectedListObj}
                        setSelectedListObj={setSelectedListObj}
                        reloadSelectedList={reloadSelectedList}
                        setReloadSelectedList={setReloadSelectedList}/>
                    {
                        !isManageQuery &&
                        <SaveListDialog
                            username={user.mail}
                            idToken={idToken}
                            open={open}
                            setOpen={setOpen}
                            value={value}
                            openedfrom="bespoke"
                            filterName={parameter.name}
                            setCustomParameterValues={setCustomParameterValues}
                            setSelectedListObj={setSelectedListObj}
                            setReloadSelectedList={setReloadSelectedList}
                            updateStausMessage={updateStausMessage} />
                    }

                    <OverwriteListDialog 
                        idToken={idToken} 
                        openOverwriteListDialog={openOverwriteListDialog} 
                        setOpenOverwriteListDialog={setOpenOverwriteListDialog} 
                        selectedListObj={selectedListObj}
                        setSelectedListObj={setSelectedListObj}
                        values={value}
                        setOpen={setOpen}
                        updateStausMessage={updateStausMessage}
                        setReloadSelectedList={setReloadSelectedList} />
                </>
                : ""
            }
        </div>
    )
}

export default CustomViewParameter